import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TripListComponent} from "./components/trip-list/trip-list.component";
import {ApiService} from "./services/api/api.service";
import {HttpClientModule} from "@angular/common/http";
import {TripDataComponent} from "./components/trip-data/trip-data.component";
import {FormsModule} from "@angular/forms";
import {SlotTabComponent} from "./components/slot-tab/slot-tab.component";
import {AccomodationdayListComponent} from "./components/accomodationday-list/accomodationday-list.component";
import {BookedRoomDataComponent} from "./components/booked-room-data/booked-room-data.component";
import {RoomSelectorComponent} from "./components/room-selector/room-selector.component";
import {SlotDataComponent} from "./components/slot-data/slot-data.component";
import {HeaderComponent} from "./components/header/header.component";
import {RouterModule, Routes} from "@angular/router";
import {TripComponent} from "./components/trip/trip.component";
import {AccomodationListComponent} from "./components/accomodation-list/accomodation-list.component";
import {AccomodationRoomListComponent} from "./components/accomodation-room-list/accomodation-room-list.component";
import {AccomodationRoomDataComponent} from "./components/accomodation-room-data/accomodation-room-data.component";
import {AccomodationComponent} from "./components/accomodation/accomodation.component";
import {AccomodationDataComponent} from "./components/accomodation-data/accomodation-data.component";
import {MenuComponent} from "./components/menu/menu.component";


const routes: Routes = [
    {
        path: '',
        component: TripComponent
    },
    {
        path: 'accomodations',
        component: AccomodationComponent
    }
];

@NgModule({
    declarations: [
        AppComponent,
        TripListComponent,
        TripDataComponent,
        SlotTabComponent,
        AccomodationdayListComponent,
        BookedRoomDataComponent,
        RoomSelectorComponent,
        SlotDataComponent,
        HeaderComponent,
        TripComponent,
        AccomodationListComponent,
        AccomodationRoomListComponent,
        AccomodationRoomDataComponent,
        AccomodationComponent,
        AccomodationDataComponent,
        MenuComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        RouterModule.forRoot(routes)
    ],
    providers: [ApiService],
    bootstrap: [AppComponent],
    entryComponents: [RoomSelectorComponent]
})
export class AppModule {
}

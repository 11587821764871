import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-accomodationday-list',
    templateUrl: './accomodationday-list.component.html',
    styleUrls: ['./accomodationday-list.component.scss']
})
export class AccomodationdayListComponent implements OnInit {

    @Input() slot: any;
    @Output() accomodationDayChange = new EventEmitter<any>();
    selected:any;

    constructor() {
    }

    ngOnInit() {
    }


    isSelected(day): boolean
    {
      return this.selected && (("id" in this.selected && "id" in day && (this.selected.id == day.id)) ||
        ("internal_id" in this.selected && "internal_id" in day && this.selected.internal_id == day.internal_id));
    }

    public stringToDate(date: string) {
        return new Date(date);
    }

    public onAccomodationDayChange(day) {
        this.selected = day;
        this.accomodationDayChange.emit(day)
    }

    addAccomodationDay() {
        this.slot.accomodation_days.push({
            "internal_id": Guid.create().toString(),
            "booked_rooms": [],
            "name": "New Day"
        });
    }

    deleteAccomodationDay(day: any) {
        let result = confirm("Are you sure to delete this accomodation day?");
        if (result) {
            let index = -1;
            if ("id" in day) {
                index = this.slot.accomodation_days.findIndex((item) => {
                    return ("id" in item) ? item.id == day.id : false;
                })
            }
            if ("internal_id" in day) {
                index = this.slot.accomodation_days.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == day.internal_id : false;
                })
            }
            if (index > -1) {
                this.slot.accomodation_days.splice(index, 1)
            }
        }
    }
}

import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RoomSelectorComponent} from "../../components/room-selector/room-selector.component";

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private modalService: NgbModal) {
    }

    public showRoomSelectorModal(): Promise<any> {
        return this.modalService.open(RoomSelectorComponent).result;
    }
}

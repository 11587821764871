import {Component, OnInit} from '@angular/core';
import {ApiService} from "./services/api/api.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'backend';
    trips: any;
    selectedTrip: any;


    constructor(private Api: ApiService) {
    }

    onTripChange(trip: any) {
        this.selectedTrip = trip;
    }

    ngOnInit() {
        this.Api.getAllTrips().then(data => this.trips = data);
    }

}

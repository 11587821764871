import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-trip-list',
    templateUrl: './trip-list.component.html',
    styleUrls: ['./trip-list.component.scss']
})
export class TripListComponent implements OnInit {


    @Input() trips: any;
    @Output() onTripChange = new EventEmitter<any>();
    selected: any;
    constructor() {
    }

    ngOnInit() {
    }

  isSelected(trip): boolean
  {
    return this.selected && (("id" in this.selected && "id" in trip && (this.selected.id == trip.id)) ||
      ("internal_id" in this.selected && "internal_id" in trip && this.selected.internal_id == trip.internal_id));
  }


    onTripClicked(trip) {
        this.selected = trip;
        this.onTripChange.emit(trip);
    }

    addNewTrip() {
        this.trips.push({
            "internal_id": Guid.create().toString(),
            "slots": [],
            "ref": "",
            "title": "New Trip",
            "base_price": 0
        })
    }

    deleteTrip(trip) {
        let result = confirm("Are you sure to delete trip " + trip.title + "?");
        if (result) {
            let index = -1;
            if ("id" in trip) {
                index = this.trips.findIndex((item) => {
                    return ("id" in item) ? item.id == trip.id : false;
                })
            }
            if ("internal_id" in trip) {
                index = this.trips.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == trip.internal_id : false;
                })
            }
            if (index > -1) {
                this.trips.splice(index, 1)
            }
        }
    }
}

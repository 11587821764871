import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-slot-data',
    templateUrl: './slot-data.component.html',
    styleUrls: ['./slot-data.component.scss']
})
export class SlotDataComponent implements OnInit, OnChanges {

    @Input() slot: any;
    @Input() template: boolean = false;

    currentAccomodationDay: any;
    datefrom: any;
    dateto: any;

    constructor() {
    }

    ngOnInit() {
        this.datefrom = this.dateToCalendar(this.slot.date_from);
        this.dateto = this.dateToCalendar(this.slot.date_to);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.datefrom = this.dateToCalendar(this.slot.date_from);
        this.dateto = this.dateToCalendar(this.slot.date_to);
    }

    public onAccomodationDayChange(day) {
        this.currentAccomodationDay = day;
    }

    public stringToDate(date: string) {
        return new Date(date);
    }

    public dateToCalendar(date: string) {
        let d = this.stringToDate(date);
        return {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
    }


    public onDateFromSelect(selected) {
        this.slot.date_from = selected.year + "-" + selected.month + "-" + selected.day;
    }

    public onDateToSelect(selected) {
        this.slot.date_to = selected.year + "-" + selected.month + "-" + selected.day;
    }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-accomodation-room-data',
    templateUrl: './accomodation-room-data.component.html',
    styleUrls: ['./accomodation-room-data.component.scss']
})
export class AccomodationRoomDataComponent implements OnInit {


    @Input() room: any;

    constructor() {
    }

    ngOnInit() {
    }

}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ModalService} from "../../services/modal/modal.service";
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-booked-room-data',
    templateUrl: './booked-room-data.component.html',
    styleUrls: ['./booked-room-data.component.scss']
})
export class BookedRoomDataComponent implements OnInit, OnChanges {

    @Input() day: any;
    @Input() slot: any;

    date: any;

    constructor(private ModalService: ModalService) {
    }

    ngOnInit() {
        this.date = this.dateToCalendar(this.day.date);
        console.log(this.day);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.date = this.dateToCalendar(this.day.date);
    }


    addRoom() {
        this.ModalService.showRoomSelectorModal().then((room) => {
            this.day.booked_rooms.push(
                {
                    "internal_id": Guid.create().toString(),
                    "room": room,
                    "number": 1,
                    "price": 0,
                    "booked": 0,
                    "erasable": "",
                    "treatment": ""
                });
        })
    }

    public stringToDate(date: string) {
        return new Date(date);
    }

    public dateToCalendar(date: string) {
        let d = this.stringToDate(date);
        return {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
    }

    public onDateSelect(selected) {
        this.day.date = selected.year + "-" + selected.month + "-" + selected.day;
    }

    removeBookedRoom(room: any) {
        let result = confirm("Are you sure to delete this room?");
        if (result) {
            let index = -1;
            if ("id" in room) {
                index = this.day.booked_rooms.findIndex((item) => {
                    return ("id" in item) ? item.id == room.id : false;
                })
            }
            if ("internal_id" in room) {
                index = this.day.booked_rooms.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == room.internal_id : false;
                })
            }
            if (index > -1) {
                this.day.booked_rooms.splice(index, 1)
            }
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-trip-data',
    templateUrl: './trip-data.component.html',
    styleUrls: ['./trip-data.component.scss']
})
export class TripDataComponent implements OnInit {
    @Input() trip: any;

    constructor() {
    }

    ngOnInit() {
    }
}

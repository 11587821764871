import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api/api.service";

@Component({
    selector: 'app-accomodation',
    templateUrl: './accomodation.component.html',
    styleUrls: ['./accomodation.component.scss']
})
export class AccomodationComponent implements OnInit {

    accomodations: any;
    currentAccomodation: any;

    constructor(private Api: ApiService) {
    }

    ngOnInit() {
        this.Api.getAllAccomodations().then(accomodations => this.accomodations = accomodations);
    }

    onAccomodationChange(accomodation) {
        this.currentAccomodation = accomodation;
    }
}

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {ApiService} from "../../services/api/api.service";

@Component({
    selector: 'app-room-selector',
    templateUrl: './room-selector.component.html',
    styleUrls: ['./room-selector.component.scss']
})
export class RoomSelectorComponent implements OnInit {

    public accomodations: any;
    public currentAccomodation: any;
    search = (text$: Observable<any>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term.length < 2 ? [] : this.accomodations.filter(a => a.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
        );
    format = (value: any) =>
        value.name;

    constructor(private NgbActiveModal: NgbActiveModal, private ApiService: ApiService) {
    }

    ngOnInit() {
        this.ApiService.getAllAccomodations().then(accomodations => this.accomodations = accomodations);
    }

    public closeModal() {
        this.NgbActiveModal.close("OK");
    }

    public onRoomClicked(room) {
        this.NgbActiveModal.close(room);
    }

    public dataAvailable(data) {
        return data instanceof Object;
    }
}

import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api/api.service";

@Component({
    selector: 'app-trip',
    templateUrl: './trip.component.html',
    styleUrls: ['./trip.component.scss']
})
export class TripComponent implements OnInit {

    title = 'backend';
    trips: any;
    selectedTrip: any;


    constructor(private Api: ApiService) {
    }

    onTripChange(trip: any) {
        this.selectedTrip = trip;
    }

    ngOnInit() {
        this.Api.getAllTrips().then(data => this.trips = data);
    }

}

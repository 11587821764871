import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private api_base = "http://giroliberobooking.noooserver.com/app_dev.php/api";

    constructor(private Http: HttpClient) {

    }

    public getAllTrips() {
        return this.Http.get(this.api_base + "/trips").toPromise();
    }


    public getAllAccomodations() {
        return this.Http.get(this.api_base + "/accomodations").toPromise();
    }
}

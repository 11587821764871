import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-accomodation-data',
    templateUrl: './accomodation-data.component.html',
    styleUrls: ['./accomodation-data.component.scss']
})
export class AccomodationDataComponent implements OnInit {

    @Input() accomodation: any;
    currentRoom: any;

    constructor() {
    }

    ngOnInit() {
    }

    public onRoomChange(room) {
        this.currentRoom = room;
    }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-accomodation-room-list',
    templateUrl: './accomodation-room-list.component.html',
    styleUrls: ['./accomodation-room-list.component.scss']
})
export class AccomodationRoomListComponent implements OnInit {

    @Input() accomodation: any;
    @Output() roomChange = new EventEmitter<any>();
    selected: any;

    constructor() {
    }

    ngOnInit() {
    }


    public onRoomChange(room) {
        this.selected = room;
        this.roomChange.emit(room)
    }

    isSelected(room): boolean
    {
      return this.selected && (("id" in this.selected && "id" in room && (this.selected.id == room.id)) ||
        ("internal_id" in this.selected && "internal_id" in room && this.selected.internal_id == room.internal_id));
    }

    addRoom() {
        this.accomodation.rooms.push({
            "accomodation": "Hotel Colonna",
            "internal_id": Guid.create().toString(),
            "name": "New Room",
            "number_of_adults": 0,
            "number_of_children": 0,
            "number_of_newborns": 0,
            "min_number_of_adults": 0,
            "min_number_of_children": 0,
            "min_number_of_newborns": 0
        });
    }

    deleteRoom(room: any) {
        let result = confirm("Are you sure to delete this room?");
        if (result) {
            let index = -1;
            if ("id" in room) {
                index = this.accomodation.rooms.findIndex((item) => {
                    return ("id" in item) ? item.id == room.id : false;
                })
            }
            if ("internal_id" in room) {
                index = this.accomodation.rooms.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == room.internal_id : false;
                })
            }
            if (index > -1) {
                this.accomodation.rooms.splice(index, 1)
            }
        }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-slot-tab',
    templateUrl: './slot-tab.component.html',
    styleUrls: ['./slot-tab.component.scss']
})
export class SlotTabComponent implements OnInit {

    @Input() trip: any;
    currentAccomodationDay: any;
    date: any;

    constructor() {
    }

    ngOnInit() {
    }

    public stringToDate(date: string) {
        return new Date(date);
    }

    public dateToCalendar(date: string) {
        let d = this.stringToDate(date);
        return {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
    }

    public onAccomodationDayChange(day) {
        this.currentAccomodationDay = day;
        let d = new Date(this.currentAccomodationDay.date);
        this.date = {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
    }

    public onDateSelect(selected) {
        this.currentAccomodationDay.date = selected.year + "-" + selected.month + "-" + selected.day;
    }

    addSlot() {
        let cloned = JSON.parse(JSON.stringify(this.trip.template_slot));
        delete cloned.id;
        cloned.internal_id = Guid.create().toString();

        this.trip.slots.push(cloned);
    }

    removeSlot(slot) {
        let result = confirm("Are you sure to delete this slot?");
        if (result) {
            let index = -1;
            if ("id" in slot) {
                index = this.trip.slots.findIndex((item) => {
                    return ("id" in item) ? item.id == slot.id : false;
                })
            }
            if ("internal_id" in slot) {
                index = this.trip.slots.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == slot.internal_id : false;
                })
            }
            if (index > -1) {
                this.trip.slots.splice(index, 1)
            }
        }
    }

    cloneSlot(slot: any) {
        let cloned = JSON.parse(JSON.stringify(slot));
        delete cloned.id;
        cloned.internal_id = Guid.create().toString();

        this.trip.slots.push(cloned);
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Guid} from "guid-typescript";

@Component({
    selector: 'app-accomodation-list',
    templateUrl: './accomodation-list.component.html',
    styleUrls: ['./accomodation-list.component.scss']
})
export class AccomodationListComponent implements OnInit {

    @Input() accomodations: any;
    @Output() accomodationChange = new EventEmitter<any>();
    selected : any;

    constructor() {
    }

    ngOnInit() {
    }


    isSelected(accomodation): boolean
    {
        return this.selected && (("id" in this.selected && "id" in accomodation && (this.selected.id == accomodation.id)) ||
          ("internal_id" in this.selected && "internal_id" in accomodation && this.selected.internal_id == accomodation.internal_id));
    }

    public onAccomodationChange(accomodation) {
        this.selected = accomodation;
        this.accomodationChange.emit(accomodation)
    }

    addAccomodation() {
        this.accomodations.push({
            "internal_id": Guid.create().toString(),
            "name": "New Accomodation",
            "rooms": []
        });
    }

    deleteAccomodation(accomodation: any) {
        let result = confirm("Are you sure to delete this accomodation?");
        if (result) {
            let index = -1;
            if ("id" in accomodation) {
                index = this.accomodations.findIndex((item) => {
                    return ("id" in item) ? item.id == accomodation.id : false;
                })
            }
            if ("internal_id" in accomodation) {
                index = this.accomodations.findIndex((item) => {
                    return ("internal_id" in item) ? item.internal_id == accomodation.internal_id : false;
                })
            }
            if (index > -1) {
                this.accomodations.splice(index, 1)
            }
        }
    }

}
